import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";

const DataProtection = () => (
  <Layout>
    <SEO title="Amber by inFeedo | General Data Protection Regulations" />
    <div class="content has-text-centered columns flex-wrap mar-top-160 gdpr-page">
            <h2 class="text-block-17 column is-12 is-paddingless">General Data Protection Regulations</h2>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
            Last Modified: January 11, 2020
            <br/><br/>
            <ul className="mg-left-1">
                <li>
                    <p><b className="has-text-dark"> Introduction</b></p>
                    <p>
                        This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, in conjunction with your access to and use of our website, www.infeedo.com (“Website”). If you see an undefined term in this Privacy Policy, it has the same definition as in our Terms of Service. When this policy mentions “inFeedo,” “we,” “us,” or “our” it refers to the inFeedo Pvt. Ltd. and any other holding/subsidiary/associate company, being the company that is responsible for your information under this Privacy Policy. You may access inFeedo’s Privacy Policy from <a href="https://infeedo.com/privacy-policy">here</a>.
                    </p>
                    <p>
                        <b>
                            If you are at this page, then you are inFeedo’s client/customer/user, who are EU Residents, to whom the General Data Protection Regulations apply. The General Data Protection Regulation (“GDPR”) (EU) 2016/679 is a regulation in EU law on data protection and privacy for all individuals within the European Union. It also addresses the export of personal data outside the EU. The GDPR places limits on the gathering and use of personal data and establishes clear responsibilities for companies that collect it, as well as clear rights for people whose data is gathered.
                        </b>
                    </p>
                    <p>
                        This Policy describes how inFeedo collects, uses and discloses information, and what choices you have with respect to the information.
                    </p>
                </li>
                <br/>
                <li>
                    <p><b className="has-text-dark">Services</b></p>
                    <p>
                        <i>
                            It is inFeedo's policy to respect your privacy regarding any information we may collect while using our software applications and websites, collectively called the Services.
                        </i>
                    </p>
                    <p>
                        This Policy applies when you use our Services. For our customers and their employees / third parties, a link to this privacy policy is also available in “My Profile” section of the application.
                    </p>
                    <p>
                        <b>Data Controller and Data Processor</b>
                        <br/> 
                        We process two main types of personal data. </p>
                    <p>
                        <b>Customer Data</b> - Personal data that forms part of data that is provided by our customers and their end-users while they use the Software.
                    </p>
                    <p>
                        <b>Other Data</b> - Personal data about our customers, visitors and other individuals that is collected and processed directly by us.
                    </p>
                    <p>
                        Our Customers are the controller of Customer Data. inFeedo is the processor of Customer Data and is also the Controller of Other Data. 

                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Information We Collect</b><br/><br/>
                    <p>This <a href="https://infeedo.com/data-protection">DPA</a> will take effect on the Effective Date and, notwithstanding expiry of the Term, remain in effect until, and automatically expire upon, deletion of all Customer Data by Company as described in this <a href="https://infeedo.com/data-protection">DPA</a> or as otherwise agreed between the parties.</p>
                    <p>
                        <b>Customer Data</b>
                    </p>
                    <p className="has-text-info">
                        As customers, the data is provided by you, when you use inFeedo’s Software and Services. 
                    </p>
                    <p>
                        <b>Other Data</b> - Personal data about our customers, visitors and other individuals that is collected and processed directly by us.
                    </p>
                    <p>This <a href="https://infeedo.com/data-protection">DPA</a> will take effect on the Effective Date and, notwithstanding expiry of the Term, remain in effect until, and automatically expire upon, deletion of all Customer Data by Company as described in this <a href="https://infeedo.com/data-protection">DPA</a> or as otherwise agreed between the parties.</p>
                    <p>
                        Customer Data may be processed by us as a result of customer’s use of the Services when our customers, or their end-users, input or upload information into the Service. For example, customers who use the Software may upload Customer Data about themselves or their employees.

                        This data includes name, email address, phone number, landline number, job title for your employees. We collect billing details for invoice purposes.  
                    </p>
                    <p className="has-text-info">
                        We also collect data when you use our Website:
                    </p>
                    <ul>
                        <li><u>Account Information.</u> When you sign up on the Website, we require certain information such as your first name, last name, and email address. To use certain features of the Website, we may ask you to provide additional information, which may include your address, and phone number.</li>

                        <li><u>Payment Information.</u> To use certain features of the Website (such as to make payment towards the license fee/products and/or services), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments via the payment gateway.</li>

                        <li><u>Communications with inFeedo.</u> When you communicate with inFeedo or use the Website to communicate with us, we collect information about your communication and any information you choose to provide.</li>

                        <li><u>Information you choose to give us.</u> You may choose to provide us with additional personal information in order to obtain a better user experience when using the Website. This additional information will be processed based on your consent.</li>

                        <li><u>Other Information.</u> You may otherwise choose to provide us information when you fill in a form, conduct a search, respond to surveys, post to community forums, participate in promotions, or use other features on the Website (if available and applicable).</li>

                        <li><u>Information provided to the payment gateway.</u> The payment gateway may require the information as is necessary for the adequate performance of the contract with you and to comply with applicable law (such as anti-money laundering regulations). Without it, you will not be able to use the payment gateway. Such information that is collected will be controlled by and shall be liable to be protected by the payment gateway provider under its own terms of service and privacy policy.</li>

                        <li><u>Information We Automatically Collect from Your Use of the Website.</u> When you use the Website, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Website.</li>

                        <li><u>Geo-location Information.</u> When you use certain features of the Website, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. The Website may also collect this information even when you are not using the app if this connection is enabled through your settings or device permissions.</li>

                        <li><u>Usage Information.</u> We collect information about your interactions with the Website such as the pages or content you view, your searches for a product/service that is provided by inFeedo, and other actions on the Website.</li>

                        <li><u>Log Data and Device Information.</u> We automatically collect log data and device information when you access and use the Website, even if you have not created an account with inFeedo and/or the Website or logged in. That information includes, among other things: details about how you’ve used the Website (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Website.</li>

                        <li><u>Cookies and Similar Technologies.</u> We use cookies and other similar technologies. We may also allow our business partners to use these tracking technologies on the Website, or engage others to track your behavior on our behalf.</li>

                        <li><u>Information We Collect from Third Parties.</u> inFeedo may collect information, including personal information, which others provide about you when they use the Website or reach the Website through a third-party, or obtain information from other sources and combine that with information we collect through the Website. We do not control, supervise or are responsible for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.</li>

                        <li><u>Other Sources.</u> To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with information we have about you. We may receive information about you and your activities on and off the Website through partnerships, or about your experiences and interactions from our partner ad networks.</li>
                    </ul>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">How We Use Your Data</b><br/><br/>
                    <p>
                        How we use your personal data will depend on which Services you use and how you use those Services.
                        <br/>
                        Customer Data will be used by inFeedo in accordance with Customer’s instructions, including any applicable terms in the Customer Agreement and as required by applicable law. inFeedo is a processor of Customer Data and Customer is the controller. 
                        <br/>
                        Other Data is used by us to provide our services, send our newsletters and to communicate with you by responding to your requests, comments and questions.
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Lawful bases for processing</b><br/><br/>
                    <p>
                        <i className="has-text-info">We have lawful bases to process your personal data. We also use your consent as bases for lawfully processing your personal data.</i>
                        <br/>
                        We process your personal data only when we have a lawful basis. The Customer Data is processed upon signing accepting the terms of service via our Order Form/ the agreement executed between inFeedo and its customers and consents, which forms the lawful basis for processing personal data. For certain processing, such as specific direct marketing, we may also use legitimate interests as provided under the Data Protection Regulations.
                        <br/>
                        In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person.
                        <br/>
                        Where you have consented to a particular processing, you have a right to withdraw the consent at any time. 
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">How we use Customer data</b><br/><br/>
                    <p>
                        <i className="has-text-info">We use your data to authenticate you and authorize access to our Services</i>
                        <br/>
                        We only process Customer Data on behalf of our customers and in accordance with their instructions provided in the applicable Services agreement with us. We use the data that we have about you to provide our Services and provide customer support to you. In each case, inFeedo collects such information only in so far as is necessary or appropriate to fulfil the purpose of the interaction with our Services.
                        <br/>
                        <ul>
                            <li>
                                <b>To send emails and other communications.</b> We may send you Service, technical and other administrative emails, messages and other types of communications. We may also contact you to inform you about changes in our Services and our Services offerings. These communications are considered part of the Services and you may not opt out of them.
                            </li>
                            <li>
                                <b>Customer Support.</b> If you send us a request (for example via a support email or via one of our feedback mechanisms), we respond to your request or to help your issues. We may also gain access to the personal data stored within PE Front Office as a result of the support.
                            </li>
                            <li>
                                For any other purpose as provided for in the Services Agreement between us and the customer, or as otherwise authorized by the customer;
                            </li>
                            <li>
                                In accordance with or as may be required by law.
                            </li>
                        </ul>
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">How we use Other data</b><br/><br/>
                    <p>
                        <i className="has-text-info">We may send you Service related messages or marketing / promotional materials. You may choose to restrict the collection or use of your personal information </i>
                        <br/>
                        We will update you with improvements in our services, new features and from time to time also carry out direct marketing of our products and services. Direct marketing is carried out only if you consent to receiving such communications from us or we have a legitimate purpose for doing so.
                        <br/>
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Users under 16 years of age</b><br/><br/>
                    <p>
                        <i className="has-text-info">The Sites and Services do not knowingly collect personal information from users under the age of 16</i>
                        <br/>
                        If you are under the age of 16, you are not permitted to use the Sites and Services or to disclose Personal Information. If we learn we have collected or received Personal Information from a child under 16, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us.
                        <br/>
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Data Retention Policy</b><br/><br/>
                    <p>
                        We will retain your personal information for as long as is needed to fulfil the purposes outlined in this Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).
                    </p>
                    <p>
                        <i className="has-text-info">
                            Customer Data - We retain your information for as long as you continue to use the Software and Services. We may also retain your personal information for extended period under applicable statutory laws.
                        </i>
                        <br/>
                            inFeedo will retain Customer Data in accordance with a Customer’s instructions, including any applicable terms in the terms and conditions of our Order Form and the licensing agreements that are executed between inFeedo and its customers and as required by applicable laws. When the license to use the Software is withdrawn then we delete all personal information about you within a specific period.
                        <br/>
                        <br/>
                        <i className="has-text-info">
                            Other Data - We retain your information for as long as necessary for the purposes that we have described in this Policy
                        </i>
                        <br/>
                        <br/>
                        inFeedo may retain Other Information pertaining to you for as long as necessary for the purposes described in this Policy.
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Your Rights</b><br/><br/>
                    <p>
                        You can request to access, update or correct your personal information. You also have the right to object to direct marketing. 
                        <br/>
                        Under the GDPR regulations, your personal information is processed based on legitimate interests, you have the right to object to the processing on grounds relating to your specific situation. Under GDPR you may also have the right to request to have your personal information deleted or restricted and ask for portability of your personal information.

                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Your Rights to Control Data </b><br/><br/>
                    <i className="has-text-info">
                        Whenever you use our Services, we aim to provide you easy means to access, modify, delete, object to or restrict use of your personal information
                    </i>
                    <p>
                        We strive to give you ways to access, update/modify your data quickly or to delete it unless we have to keep that information for legal purposes. Some rights can be access from within the inFeedo application. For visitors, these rights can be exercised by contacting us with your specific request.

                        <br/>
                        <ul>
                            <li>
                                <b>Change or Correct Data:</b> 
                                You can ask us to erase or delete all or some of your personal data (e.g. if it is no longer necessary to provide Services to you).
                            </li>
                            <li>
                                <b>Delete Data:</b> 
                                You can edit some of your personal data through your account.  You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.
                            </li>
                            <li>
                                <b>Object to, or Limit or Restrict, Use of Data:</b> 
                                You can ask us to stop using all or some of your personal data (e.g. if we have no legal right to keep using it) or to limit our use of it (e.g. if your personal data is inaccurate or unlawfully held).
                            </li>
                            <li>
                                <b>Right to Access and/or Take Your Data:</b> 
                                You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.
                            </li>
                        </ul>
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Data Retention:</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            We keep some personal data even after termination/suspension of the use of the Software 
                        </i>
                        
                        <br/>
                        Once you choose to terminate the relationship with inFeedo, we generally delete your personal information within 180 (one hundred and eighty) days of termination of Services unless otherwise requested by you. Some information that is necessary for statutory obligations such as records of payment processing, invoicing data will be retained as necessary.
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Your information shared with others</b><br/><br/>
                    <b className="has-text-dark">Recipients of your data</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            Your data will be shared with other recipients in order to provide you with Services. We use third party servers/systems, that have been listed as Annexure in this Policy. These third-parties provide the services necessary for use of the Software.
                            <br/>
                            <br/>
                            While we aim to limit the sharing of your data, at times, it is necessary to share your data with certain service providers.  Examples of when and for what purpose your data is shared include data center/ hosting services, email marketing services, etc.
                        </i>
                        <br/>
                        <br/>
                        The following categories of recipient will most likely receive your data in order for us to provide services to you
                    </p>
                    <p>
                        <i className="has-text-info">
                            Your data will be shared with other recipients in order to provide you with Services. We use third party servers/systems, that have been listed as Annexure in this Policy. These third-parties provide the services necessary for use of the Software.
                            <br/>
                            <br/>
                            While we aim to limit the sharing of your data, at times, it is necessary to share your data with certain service providers.  Examples of when and for what purpose your data is shared include data center/ hosting services, email marketing services, etc.
                        </i>
                        <br/>
                        <br/>
                        The following categories of recipient will most likely receive your data in order for us to provide services to you
                        <br/>
                        <br/>
                        <b>To Comply with Laws</b> - If we receive a request for information, we may disclose if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal process. 
                        <br/>
                        <br/>
                        <b>Cross-Border Data Transfers</b> 
                        <br/>
                        <br/>
                        <i className="has-text-info">
                            Your data will be stored and processed in multiple countries including outside of the European Union (EU) Region
                        </i> 
                        <br/>
                        <br/>
                        Since we are an international company, your data will be processed outside of the EU region. Your data will be processed within Third Party Data Centres in USA, currently our servers situated at AWS, West Virginia, United States of America. 
                        <br/>
                        <br/>
                        inFeedo offers European Union Model Clauses, also known as Standard Contractual Clauses, to meet the adequacy and security requirements for our Customers that operate in the European Union, and other international transfers of Customer Data. These clauses are contractual commitments between parties transferring personal data binding them to protect the privacy and security of the data.
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Security Measures to Protect your Data</b><br/><br/>
                    <b className="has-text-dark">Security Measures</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            We implement security controls to prevent breaches and unauthorised access to your data.
                        </i>
                        
                        <br/>
                        We maintain reasonable and appropriate security measures to protect Customer Data and Other Data from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.
                        <br/>
                        Examples of security measures include physical access controls, HTTPS, restricted access to data, monitoring for threats and vulnerabilities etc.
                        <br/>
                        We also subject our services to internationally recognised certification and attestation standards. Details about our security measures are available at our website.
                    </p>
                </li>
                <br/>
                <li>
                    <b className="has-text-danger">Security Measures to Protect your Data</b><br/><br/>
                    <b className="has-text-dark">Security Measures</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            We implement security controls to prevent breaches and unauthorised access to your data.
                        </i>
                        
                        <br/>
                        We maintain reasonable and appropriate security measures to protect Customer Data and Other Data from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.
                        <br/>
                        Examples of security measures include physical access controls, HTTPS, restricted access to data, monitoring for threats and vulnerabilities etc.
                        <br/>
                        We also subject our services to internationally recognised certification and attestation standards. Details about our security measures are available at our website.
                    </p>

                    <b className="has-text-dark">Protection of Personal Information</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            The Website and Services uses commercial efforts to maintain safeguards for protection of your Personal Information
                        </i>
                        
                        <br/>
                        inFeedo takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.  
                    </p>
                    <br/><br/>
                    <b className="has-text-dark">Data Protection Officer </b><br/><br/>
                    Seema Pathak <br/>
                    <a href="mailto:privacy@infeedo.com">privacy@infeedo.com </a>
                    <br/>
                    <br/>

                    As a resident of the European Economic Area you have additional rights.  If you are not satisfied with the resolution, you can also lodge a complaint with the Supervisory Authority in the country of your residence within the EU.
                    <br/>
                    <br/>
                    <b className="has-text-dark">Privacy Policy change</b><br/><br/>
                    <p>
                        <i className="has-text-info">
                            inFeedo may change this Privacy Policy from time to time, at our sole discretion.
                        </i><br/>
                        inFeedo reserves the right to modify this Policy at any time in accordance with this provision. If we make changes to this Policy, we will post the revised Privacy Policy on the Website and update the “Last Updated” date at the top of this Policy. Your continued access to or use of the Website and the Services will be subject to the revised Privacy Policy.
                    </p>
                </li>
                <br/>
            </ul>
            <br/>
            <b className="has-text-dark">Annexure</b><br/>
            <b className="">List of our GDPR compliant Subprocessors:</b>
            <ol>
                <li>Aircall.io</li>
                <li>Amazon Web Services</li>
                <li>BigQuery</li>
                <li>Document 360</li>
                <li>Docusign</li>
                <li>FrontApp</li>
                <li>G-Suite</li>
                <li>Google Analytics</li>
                <li>HubSpot</li>
                <li>LinkedIn Sales Navigator</li>
                <li>Mailgun</li>
                <li>Msg91</li>
                <li>Notion</li>
                <li>Quickbooks</li>
                <li>Recruitee</li>
                <li>Salesforce</li>
                <li>Slack</li>
                <li>Trello</li>
                <li>Typeform</li>
                <li>Vitally</li>
                <li>Zoom Meeting</li>
                <li>ZoomInfo</li>
            </ol>
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default DataProtection

